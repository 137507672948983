.text-widget {
	height: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	overflow-wrap: break-word;
	padding: 1rem;
	p {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	ol,
	ul {
		padding: 1rem;
	}

	ul {
		list-style: inherit;
	}
}

.edit-text-widget-modal {
	.pf-c-modal-box__body {
		min-height: 200px;
	}
}

.rdw-editor-wrapper {
	width: 100%;
	background-color: white;

	.rdw-editor-main {
		height: fit-content;
		cursor: text;
		background-color: white;
		margin-left: 0.375rem;
		margin-right: 0.375rem;

		.public-DraftStyleDefault-block {
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}

	.rdw-fontsize-dropdown {
		width: 4em;
	}

	.rdw-editor-toolbar {
		gap: 0.5rem;
		align-items: center;
		padding-bottom: 0.5rem;
	}

	.pf-c-dropdown {
		.fact-alias-item {
			display: flex;
			gap: 1rem;
			justify-content: space-between;
			width: 100%;

			.fact-alias-actions {
				display: flex;
				gap: 0.5rem;
				align-items: center;
			}
		}
	}
}
