@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.notifications-container {
	.pf-c-input-group {
		> button {
			&.pf-c-button,
			&.pf-c-button,
			&.pf-c-button {
				border-bottom: 0.063rem solid #8a8d90;
				border-top: 0.063rem solid #f0f0f0;
				border-right: 0.063rem solid #f0f0f0;
				border-left: none;
				padding-top: 0.25rem;

				border-top-right-radius: 0.375rem;
				border-bottom-right-radius: 0.375rem;

				&::after {
					border: none;
				}
			}

			&.pf-c-button:disabled,
			&.pf-c-button.pf-m-disabled,
			&.pf-c-button.pf-m-aria-disabled {
				background-color: transparent;
			}
		}
	}

	.pf-c-text-input-group__text {
		&::before,
		&::after {
			border-right: none;
			border-top-left-radius: 0.375rem;
			border-bottom-left-radius: 0.375rem;
		}
	}

	// .pf-c-button.pf-m-control::after {
	// 	border: none;
	// }

	.pf-c-form__group-control {
		@include theme-aware('color', 'text-primary');
	}

	.quickfilter-label {
		padding-left: 1rem;
		padding-right: 1rem;
		margin-right: 0.5rem;
		a {
			font-size: $text-smm;
		}
	}

	.pf-c-expandable-section {
		border-bottom-width: 0.1rem;
		background-color: #f5f5f5;
		margin-bottom: 0.5rem;
		border-radius: 0.375rem;
		padding: 0.25rem;
		padding-left: 0.75rem;
		@include theme-aware('border-bottom-color', 'text-primary');
	}

	.pf-c-expandable-section__toggle {
		font-size: $text-md;
		@include theme-aware('color', 'text-primary');

		&:hover {
			@include theme-aware('color', 'text-primary-hover');

			.pf-c-expandable-section__toggle-icon {
				@include theme-aware('color', 'text-primary-hover');
			}
		}

		.pf-c-expandable-section__toggle-icon {
			@include theme-aware('color', 'text-primary');
		}
	}

	.pf-c-notification-drawer {
		background-color: #fff;
	}
}
