@import '../../shared/styles/variables';
@import '../../shared/styles/mixins';
@import '../../shared/styles/colors';

.clipboard-icon {
	float: right;
	display: inline-block;
}

.metadata-container {
	padding: 1rem;
	display: block;
	width: 100%;
	min-height: 50vh;
	margin-top: 1rem;
}
