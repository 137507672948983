@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.categories {
	h1 {
		font-size: 2rem !important;
	}

	.category-title {
		font-size: $text-sm;
		margin-bottom: 0.75rem;
		@include theme-aware('color', 'text-primary');
	}

	button.pf-m-link {
		padding: 0rem;
		@include theme-aware('color', 'text-primary');
		font-weight: bold;
		font-size: 1rem;

		&:hover {
			@include theme-aware('color', 'text-primary-hover');
		}
	}

	.pf-c-expandable-section__toggle {
		font-size: $text-md;
		@include theme-aware('color', 'text-primary');

		&:hover {
			@include theme-aware('color', 'text-primary-hover');

			.pf-c-expandable-section__toggle-icon {
				@include theme-aware('color', 'text-primary-hover');
			}
		}

		.pf-c-expandable-section__toggle-icon {
			@include theme-aware('color', 'text-primary');
		}
	}

	.pf-c-expandable-section {
		border-bottom-width: 0.1rem;
		// border-bottom-style: solid;
		background-color: #f5f5f5;
		margin-bottom: 0.5rem;
		border-radius: 0.375rem;
		padding: 0.25rem;
		padding-left: 0.75rem;
		@include theme-aware('border-bottom-color', 'text-primary');
	}

	.pf-c-expandable-section__content {
		@include theme-aware('color', 'text-primary');
		margin-bottom: 2rem;
		h2 {
			font-size: $text-lg;
		}
	}

	.chart-data {
		display: flex;
		gap: 1rem;

		.chart-section {
			flex-basis: 85%;
		}

		.insights-section {
			flex-basis: 15%;

			.generate-insights-btn {
				margin-right: 1rem;
			}
		}
	}
}
